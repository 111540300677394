function fillNodesWithData(selectors, value) {
	var nodes = document.querySelectorAll(selectors);

	for (var i = 0; i < nodes.length; i++) {
		if (nodes[i].tagName != 'INPUT') {
			nodes[i].innerHTML = value;
		} else {
			nodes[i].value = value;
		}
	}

	if (selectors == ".packeta-selector-branch-id") {
		DispensingPoints.select(value);
	}
}

window.addEventListener('message', function(event) {
	if (event.data.message === 'pickerResult') {
		DispensingPoints.select(event.data.point.id);

		$(dispensingPointsModal).modal("hide");
	}
	else if(event.data.point_id){ //DPD PL
		DispensingPoints.select(event.data.point_id);

		$(dispensingPointsModal).modal("hide");
	}

	if (event.origin == 'https://serv.meest.com') {
		let data = JSON.parse(event.data);
		DispensingPoints.select(data.id);

		$(dispensingPointsModal).modal("hide");
	}
});


var DispensingPoints = function () {
	var dispensingPointLink = ".s31-select-dispensing-point",
		$currentOpenAnchor;

	return {
		init: function () {
			$(dispensingPointLink).on("click", function (e) {
				$(this).parents(".s31-checkout-deliverypayment-item").find("input[type='radio']").click();

				var type = $(this).data("type");

				$currentOpenAnchor = $(this);

				if (type === "cpnapostu" || type === "cpbalikovna") {
					let typePost = "POST_OFFICE";
					if (type == "cpbalikovna") {
						typePost = "BALIKOVNY";
					}

					$(dispensingPointsModal).find(".s31-dispensingPoints-popupBodyCP").empty().html("" +
						'<iframe title="Výběr místa pro vyzvednutí zásilky"' +
						'src="https://b2c.cpost.cz/locations/?type=' + typePost + '" allow="geolocation" width="100%" height="100%" />'
					);
					$(dispensingPointsModal).modal("show");
				}
				else if (type === "dpdpl_pickup") {
					var code = $(this).data("code");

					var dpdUrl = "//pudofinder.dpd.com.pl/widget?key=e0cb5b1bf06c52202eaf12d81eeec7eb&query=PL&swip_box=1";
					if(code == "DPDPLPICK") {
						var dpdUrl = "//pudofinder.dpd.com.pl/widget?key=e0cb5b1bf06c52202eaf12d81eeec7eb&query=PL&points_with_services=1";
					}

					$(dispensingPointsModal).find(".s31-dispensingPoints-popupBodyCP").empty().html(
						'<iframe src="' + dpdUrl + '" allow="geolocation" width="100%" height="100%" />'
					);
					$(dispensingPointsModal).modal("show");
				} else if (type === "meest") {
					$(dispensingPointsModal).find(".s31-dispensingPoints-popupBodyCP").empty().html(
						'<iframe src="https://serv.meest.com/services/branches/?country=ua&org=36,38&partnerKey=PACKWAYMST" allow="geolocation" width="100%" height="100%" />'
					);
					$(dispensingPointsModal).modal("show");
				}

			});
		},
		select: function (branchId) {
			$('body, html').animate({
				scrollTop: $(".s31-checkout-delivery").offset().top - 50
			}, 1);

			$.ajax({
				method: "PUT",
				cache: false,
				url: "/ajax/shipping/dispensing-point-ext/" + branchId,
				success: function (response) {
					var deliveryItem = $currentOpenAnchor.parent();
					if ($currentOpenAnchor.hasClass("s31-checkout-deliverypayment-item")) {
						deliveryItem = $currentOpenAnchor;
					}
					deliveryItem.find(".s31-dispensing-point-content").html(response);
					deliveryItem.find(".s31-checkout-delivery-method input")[0].dispatchEvent(new Event('change'));
				},
				error: function (jqXHR) {
					var errorText = "Výdejní místo se nepodařilo nastavit. Zkuste to, prosím, později nebo vyberte jiné.";
					$currentOpenAnchor.parent().find(".s31-dispensing-point-content").html(errorText);
				}
			});
		}
	};
}();

$(function () {
	DispensingPoints.init();
});

